import { Splide } from "@splidejs/splide";
import { Intersection } from "@splidejs/splide-extension-intersection";

export const areaSlider = () => {
  const splide = new Splide(".js-area-slider", {
    type: "loop",
    gap: "4rem",
    padding: "2rem",
    lazyLoad: "nearby",
    perMove: 1,
    pagination: false,
    fixedWidth: null,
    cloneStatus: false, // remove active class from cloned slides
    updateOnMove: true, // add active class right after slide change happens
    drag: false,
    focus: "center",
    autoplay: "pause",
    intersection: {
      inView: {
        autoplay: true,
      },
    },
    outView: {
      autoplay: false,
    },
    interval: 5000,
    speed: 1000,
    pauseOnHover: false,
    resetProgress: false,
    mediaQuery: "min",
    breakpoints: {
      769: {
        gap: "2rem",
        fixedWidth: "38rem",
      },
      1301: {
        gap: "-6rem",
        fixedWidth: "43rem",
      },
    },
  });
  splide.mount({ Intersection });
};
