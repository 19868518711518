import $ from "jquery";

export const toggleHeaderOnScroll = () => {
  const header = $(".header");
  const body = $("body");
  const headerHeight = header.outerHeight();
  let beforeScrollTop = 0;
  $(window).on("scroll", function () {
    const scrollTop = $(this).scrollTop();
    if (scrollTop > beforeScrollTop && scrollTop > headerHeight) {
      body.addClass("is-scrolling-down");
    } else {
      body.removeClass("is-scrolling-down");
    }
    beforeScrollTop = scrollTop;
  });
};
