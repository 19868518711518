import $ from "jquery";

export const hamburgerMenu = () => {
  const btn = $("#hamburger-btn");
  const menuLinks = $("#hamburger-menu a");

  btn.on("click", function () {
    $("body").toggleClass("is-hamburger-menu-open");
  });

  menuLinks.on("click", function () {
    $("body").removeClass("is-hamburger-menu-open");
  });
};
