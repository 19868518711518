import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export const introTextSlider = () => {
  const splide = new Splide(".js-intro-text-slider", {
    type: "loop",
    gap: "8rem",
    arrows: false,
    drag: false,
    pagination: false,
    autoScroll: {
      pauseOnHover: false,
    },
    autoWidth: true,
  });

  splide.mount({ AutoScroll });
};
