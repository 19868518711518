import $ from "jquery";

export const faqAccordion = () => {
  const accordionTriggers = $(".js-accordion-trigger");

  accordionTriggers.each(function () {
    const accordionContent = $(this).find(".js-accordion-content");

    $(this).on("click", function () {
      accordionContent.slideToggle();
      $(this).toggleClass("is-open");
    });
  });
};
