import { Splide } from "@splidejs/splide";
import { Intersection } from "@splidejs/splide-extension-intersection";

export const roomSlider = () => {
  const splide = new Splide(".js-room-slider", {
    type: "fade",
    rewind: true,
    gap: "2rem",
    autoplay: "pause",
    lazyLoad: "nearby",
    intersection: {
      inView: {
        autoplay: true,
      },
    },
    outView: {
      autoplay: false,
    },
    interval: 7000,
    speed: 2000,
    pauseOnHover: false,
    resetProgress: false,
    classes: {
      pagination: "splide__pagination room-slider__pagination",
      page: "splide__pagination__page room-slider__pagination-page",
    },
  });
  splide.mount({ Intersection });
};
