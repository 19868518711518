import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export const mvSlider = () => {
  const splide = new Splide(".js-mv-slider", {
    type: "loop",
    fixedWidth: "37.5rem",
    fixedHeight: "28.4rem",
    gap: "5rem",
    arrows: false,
    drag: false,
    classes: {
      pagination: "splide__pagination mv-slider__pagination",
      page: "splide__pagination__page mv-slider__pagination-page",
    },
    autoScroll: {
      pauseOnHover: false,
    },
    mediaQuery: "min",
    breakpoints: {
      769: {
        padding: "10%",
        fixedWidth: false,
        fixedHeight: false,
      },
      1024: {
        padding: "20%",
      },

      1501: {
        padding: "24%",
      },
    },
  });
  splide.mount({ AutoScroll });
};
