import $ from "jquery";

export const handleScrolling = () => {
  let timeoutId;

  window.addEventListener("scroll", function () {
    if (!$("body").hasClass("is-scrolling")) {
      $("body").addClass("is-scrolling");
    }

    clearTimeout(timeoutId);

    timeoutId = setTimeout(function () {
      $("body").removeClass("is-scrolling");
    }, 500);
  });
};
