import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export const areaGoodsSlider = () => {
  const splide = new Splide(".js-area-goods-slider", {
    type: "loop",
    gap: "3rem",
    arrows: false,
    drag: false,
    pagination: false,
    lazyLoad: "nearby",
    autoScroll: {
      pauseOnHover: false,
    },
    autoWidth: true,
  });

  splide.mount({ AutoScroll });
};
