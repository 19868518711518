import $ from "jquery";
import { faqAccordion } from "./modules/faqAccordion";
import { mvSlider } from "./modules/mvSlider";
import { roomSlider } from "./modules/roomSlider";
import { introTextSlider } from "./modules/introTextSlider";
import { areaSlider } from "./modules/areaSlider";
import { areaGoodsSlider } from "./modules/areaGoodsSlider";
import { smoothScroll } from "./modules/smoothScroll";
import { hamburgerMenu } from "./modules/hamburgerMenu";
import "../../assets/js/inview.min.js";
import { toggleHeaderOnScroll } from "./modules/toggleHeaderOnScroll.js";
import { handleScrolling } from "./modules/handleScrolling.js";

$(function () {
  smoothScroll();
  hamburgerMenu();
  mvSlider();
  roomSlider();
  areaSlider();
  areaGoodsSlider();
  faqAccordion();
  introTextSlider();
  handleScrolling();

  if (
    !(
      navigator.userAgent.indexOf("iPhone") > 0 ||
      (navigator.userAgent.indexOf("Android") > 0 &&
        navigator.userAgent.indexOf("Mobile") > 0) ||
      navigator.userAgent.indexOf("iPad") > 0 ||
      navigator.userAgent.indexOf("Android") > 0
    )
  ) {
    // PC向けの記述
    toggleHeaderOnScroll();
  }

  $(".inview").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("inview-is-visible");
    }
  });

  $(".inview-custom").on("inview", function (event, isInView) {
    if (isInView) {
      $(this).stop().addClass("inview-is-visible");
    }
  });
});
